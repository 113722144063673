<script setup lang="ts">
import { storeToRefs } from "pinia";
import { useMemberService } from "@/stores/memberFunction/memberService";
import { useCounterStore } from "@/stores/counter";
import { useContentInfo } from "@/stores/contentInfo";

import DiscountDefaultImg from "@image/memberFunction/03_web/Discount_default.jpg";
const memberService = useMemberService();
const contentInfo = useContentInfo();
const store = useCounterStore();

const { SelectDiscountData } = storeToRefs(contentInfo);

function closeBetRecord() {
  store.hiddenAllAlert();
  store.hiddenAlertBg();
}
</script>

<template>
  <div class="sticky top-[10dvh] left-1/2 -translate-x-1/2 w-[50vw]">
    <div class="relative">
      <img
        src="@image/memberFunction/03_web/activity_detail_bg.png"
        alt=""
        class="w-full"
      />
      <h3
        class="absolute text-yellow-100 text-[16px] xl:text-[24px] font-bold top-[2.5%] left-1/2 -translate-x-1/2"
      >
        {{ $t("Context.Discount.ActivityDetails") }}
      </h3>
      <button
        type="button"
        class="absolute text-yellow-100 text-[24px] font-bold top-[2.5%] right-[3%] -translate-x-1/2"
        @click="closeBetRecord"
      >
        X
      </button>
      <div class="absolute top-[10%] left-1/2 w-full -translate-x-1/2">
        <img
          :src="
            SelectDiscountData.img_url_pc
              ? SelectDiscountData.img_url_pc
              : DiscountDefaultImg
          "
          alt=""
          class="object-contain w-[93%] rounded-[24px] relative left-1/2 -translate-x-1/2"
        />

        <div class="relative discount-table">
          <img
            src="@image/memberFunction/03_web/activity_bg.png"
            alt=""
            class="w-full px-[3%] mt-[1%]"
          />
          <p
            v-html="SelectDiscountData.sContent0"
            class="text-primary absolute w-[85%] h-[80%] top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2 overflow-y-scroll discount-scrollbar"
          ></p>
        </div>
        <button
          type="button"
          class="btn-y-border relative left-1/2 -translate-x-1/2 mt-[1em]"
        >
          <p
            class="text-secondary text-[18px] md:text-[24px] px-[2em] py-[.4em]"
          >
            {{ $t("Context.Discount.ContactService") }}
          </p>
        </button>
      </div>
    </div>
  </div>
</template>

<style>
.bgLinearMailBox {
  background: linear-gradient(45deg, #2c343e, #576879);
  border-style: solid;
  border-image: linear-gradient(45deg, #563d2a, #edbe7b) 1;
  border-image-slice: 1 1 1 1;
}
.bg-linear-mail-box-title {
  background: linear-gradient(#624380, #472161);
}
.bg-100 {
  background-size: 100% 100%;
}
.discount-scrollbar {
}
.discount-scrollbar::-webkit-scrollbar {
  width: 8px;
}

/* Track */
.discount-scrollbar::-webkit-scrollbar-track {
  background: #21252927;
  border-radius: 20px;
}

/* Handle */
.discount-scrollbar::-webkit-scrollbar-thumb {
  background: #ffffff69;
  border-radius: 20px;
}

/* Handle on hover */
.discount-scrollbar::-webkit-scrollbar-thumb:hover {
  background: #212529;
}

.discount-table table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px; /* 添加一些底部间距，根据需要调整 */
}

.discount-table th,
.discount-table td {
  border: 1px solid #ddd;
  padding: 2px;
  text-align: left;
}
</style>
