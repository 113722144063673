<script setup lang="ts">
import { ref, computed } from "vue";
import { storeToRefs } from "pinia";
import { useI18n } from "vue-i18n";
import { useCounterStore } from "@/stores/counter";
import { useMemberService } from "@/stores/memberFunction/memberService";
import { useTimeListLogic } from "@/stores/timeListLogic";
import SidebarWeb from "@/components/menberFunction/functionNavbar/sidebarWeb.vue";

const { t } = useI18n();

const store = useCounterStore();
const memberService = useMemberService();
const timeListLogic = useTimeListLogic();
const { defaultBetStatisticsData, BetStatisticsAGame, BetStatisticsASum } =
  storeToRefs(memberService);
function changeStartTime() {
  if (
    !defaultBetStatisticsData.value.sEndTime ||
    defaultBetStatisticsData.value.sEndTime <=
      defaultBetStatisticsData.value.sStartTime
  ) {
    defaultBetStatisticsData.value.sEndTime =
      defaultBetStatisticsData.value.sStartTime;
  }
}

const BetStatisticsAGameList = computed(() => {
  return Object.values(BetStatisticsAGame.value);
});

//#region 點擊查詢時間
interface TimeList {
  title: string;
  fn: Function;
  isSelect: boolean;
}
const timeLists = ref<TimeList[]>([
  {
    title: t("MemberFunction.BetRecord.Yesterday"),
    fn: getYesterdayBetList,
    isSelect: false,
  },
  {
    title: t("MemberFunction.BetRecord.Today"),
    fn: getTodayBetList,
    isSelect: true,
  },
  {
    title: t("MemberFunction.BetRecord.LastWeek"),
    fn: getLastWeekBetList,
    isSelect: false,
  },
  {
    title: t("MemberFunction.BetRecord.ThisWeek"),
    fn: getThisWeekBetList,
    isSelect: false,
  },
  {
    title: t("MemberFunction.BetRecord.ThisMonth"),
    fn: getThisMonthBetList,
    isSelect: false,
  },
]);
function selectTime(index: number) {
  timeLists.value.forEach((time, timeIndex) => {
    time.isSelect = false;
    if (timeIndex === index) {
      time.isSelect = true;
    }
  });
}

const today = new Date();
const thirtyDaysAgo = new Date(today);
thirtyDaysAgo.setDate(today.getDate() - 31);
const formatDate = (date: any) => {
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const day = date.getDate().toString().padStart(2, "0");
  return `${year}-${month}-${day}`;
};

const minDate = ref(formatDate(thirtyDaysAgo));
const maxDate = ref(formatDate(today));

function getYesterdayBetList() {
  const today = new Date();
  const day = today.getDay();
  defaultBetStatisticsData.value.sStartTime = timeListLogic.getDataDate(
    day - 1
  );
  defaultBetStatisticsData.value.sEndTime = timeListLogic.getDataDate(day - 1);
  selectTime(0);
  getBetStatisticsData();
}
function getTodayBetList() {
  const today = new Date();
  const day = today.getDay();
  defaultBetStatisticsData.value.sStartTime = timeListLogic.getDataDate(day);
  defaultBetStatisticsData.value.sEndTime = timeListLogic.getDataDate(day);

  selectTime(1);
  getBetStatisticsData();
}

function getLastWeekBetList() {
  defaultBetStatisticsData.value.sStartTime = timeListLogic.getDataDate(-6);
  defaultBetStatisticsData.value.sEndTime = timeListLogic.getDataDate(0);

  selectTime(2);
  getBetStatisticsData();
}

function getThisWeekBetList() {
  defaultBetStatisticsData.value.sStartTime = timeListLogic.getDataDate(1);
  defaultBetStatisticsData.value.sEndTime = timeListLogic.getDataDate(7);

  selectTime(3);
  getBetStatisticsData();
}

function getThisMonthBetList() {
  defaultBetStatisticsData.value.sStartTime = timeListLogic.getMonthFirthDate();
  defaultBetStatisticsData.value.sEndTime = timeListLogic.getMonthLastDate();

  selectTime(4);
  getBetStatisticsData();
}
//#endregion

function getBetStatisticsData() {
  memberService.getBetStatisticsData();
}
</script>
<template>
  <div class="px-[1em] md:px-[5dvh] pt-0 md:pt-[1vh] mb-0 md:mb-[5vh]">
    <div class="flex flex-col md:flex-row items-center">
      <div class="flex justify-between items-center w-full col-span-3">
        <label
          for="memberNumber"
          class="search-date-label relative w-full md:w-[16vw]">
          <input
            @change="changeStartTime"
            :min="minDate"
            :max="maxDate"
            type="date"
            v-model="defaultBetStatisticsData.sStartTime"
            class="search-date-border text-primary text-[12px] w-full h-[100%] md:h-[3dvh] bg-transparent text-center bg-cover bg-center placeholder:text-primary" />
        </label>
        <span class="text-primary text-[12px] md:text-[14px] m-[12px]">{{
          $t("MemberFunction.BetRecord.To")
        }}</span>
        <label
          for="memberNumber"
          class="search-date-label relative w-full md:w-[16vw]">
          <input
            type="date"
            :min="minDate"
            :max="maxDate"
            v-model="defaultBetStatisticsData.sEndTime"
            class="search-date-border text-primary text-[12px] w-full h-[100%] md:h-[3dvh] bg-transparent text-center bg-cover bg-center placeholder:text-primary" />
        </label>
        <button @click="getBetStatisticsData" class="ml-[12px]">
          <img
            src="@image/memberFunction/08/search_btn.png"
            alt=""
            class="h-auto md:h-[3dvh]" />
        </button>
      </div>
    </div>
    <ul
      class="flex justify-center md:justify-between items-center my-[1dvh] px-[1em] md:px-0">
      <li
        v-for="time in timeLists"
        :key="time.title + 'berRecord'"
        class="flex justify-center md:justify-start items-center">
        <button
          class="group relative w-[80%] md:w-[6em] xl:w-[7em] text-[12px] md:text-[14px] mr-0"
          @click="time.fn"
          :class="{
            'text-primary': !time.isSelect,
            'text-secondary': time.isSelect,
          }">
          <img
            src="@image/memberFunction/common/會員選擇無.png"
            alt=""
            v-show="!time.isSelect" />
          <img
            src="@image/memberFunction/common/會員選擇.png"
            alt=""
            v-show="time.isSelect" />
          <p
            class="absolute top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2 w-[110%]">
            {{ time.title }}
          </p>
        </button>
      </li>
    </ul>
    <div class="w-full h-[3px] bg-white/80 my-[2dvh] hidden md:block"></div>
    <div
      class="text-primary flex justify-around items-center bg-white/10 py-[.5dvh]">
      <div class="flex items-center">
        <p class="text-[14px] mr-[3vw]">
          {{ $t("MemberFunction.BetRecord.WinLoseStatistics") }}
        </p>
        <p
          class="text-[18px]"
          :class="{
            'text-yellow-200':
              BetStatisticsASum.nWinLose && BetStatisticsASum.nWinLose === 0,
            'text-red-600':
              BetStatisticsASum.nWinLose && BetStatisticsASum.nWinLose < 0,
            'text-green-600':
              BetStatisticsASum.nWinLose && BetStatisticsASum.nWinLose > 0,
          }">
          {{
            BetStatisticsASum
              ? store.getClearPotAndAddCommas(
                  BetStatisticsASum.nWinLose.toString()
                )
              : 0
          }}
        </p>
      </div>
      <div class="flex items-center">
        <p class="text-[14px] mr-[3vw]">
          {{ $t("MemberFunction.BetRecord.WagerStatistics") }}
        </p>
        <p class="text-[18px] text-yellow-200">
          {{ store.getClearPotAndAddCommas(BetStatisticsASum.nBetMoney) }}
        </p>
      </div>
    </div>

    <div
      class="relative rounded-lg my-[1dvh] px-[0] min-h-[50dvh] md:min-h-[60dvh]">
      <div class="grid grid-cols-3 gap-x-[3vw] gap-y-[1dvh] py-[1dvh]">
        <div
          class="relative"
          v-for="item in BetStatisticsAGameList"
          :key="item.sName + 'betRecordList'">
          <img src="@image/memberFunction/06/money_board.png" alt="" />
          <div
            class="absolute w-full top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-primary">
            <p class="text-[14px] md:text-[18px] mb-[.5dvh] text-center">
              {{ item.sName.toString().toUpperCase() }}
            </p>
            <div
              class="text-[12px] md:text-[16px] font-bold flex justify-around items-center">
              <p>{{ $t("MemberFunction.BetRecord.Wager") }}</p>
              <p>{{ store.getClearPotAndAddCommas(item.nBetMoney) }}</p>
            </div>
            <div
              class="text-[12px] md:text-[16px] font-bold flex justify-around items-center">
              <p>{{ $t("MemberFunction.BetRecord.WinLose") }}</p>
              <p
                :class="{
                  'text-primary': item.nWinLose === 0,
                  'text-red-600': item.nWinLose && item.nWinLose < 0,
                  'text-green-600': item.nWinLose && item.nWinLose > 0,
                }">
                {{
                  item.nWinLose
                    ? store.getClearPotAndAddCommas(item.nWinLose.toString())
                    : 0
                }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
.bg-100 {
  background-size: 100% 100%;
}
select {
  -moz-appearance: none; /* Firefox */
  -webkit-appearance: none; /* Safari and Chrome */
  appearance: none;
}
</style>
