import "./assets/main.css";

import { createApp } from "vue";
import { createPinia } from "pinia";

import i18n from "./i18n";
import { Field, Form, ErrorMessage, defineRule, configure } from "vee-validate";
import AllRules from "@vee-validate/rules";
import { localize, setLocale } from "@vee-validate/i18n";
import zhTW from "@vee-validate/i18n/dist/locale/zh_TW.json";
import App from "./App.vue";
import router from "./router";
import mitt from "mitt";
//@ts-ignore
import { imgsPreloader } from "./assets/image/imgPreloader.js";
//@ts-ignore
import imgPreloaderList from "./assets/image/imgPreloaderList.js";

if (
  import.meta.env.VITE_CONFIG_MODE == "TTBtest" ||
  import.meta.env.VITE_CONFIG_MODE == "TTB"
) {
  import("./assets/TTBstyle.css").then(() => {});
} else if (
  import.meta.env.VITE_CONFIG_MODE == "MTtest" ||
  import.meta.env.VITE_CONFIG_MODE == "MT"
) {
  import("./assets/MTstyle.css").then(() => {});
} else if (
  import.meta.env.VITE_CONFIG_MODE == "MMtest" ||
  import.meta.env.VITE_CONFIG_MODE == "MM"
) {
  import("./assets/MMstyle.css").then(() => {});
} else if (
  import.meta.env.VITE_CONFIG_MODE == "ICVtest" ||
  import.meta.env.VITE_CONFIG_MODE == "ICV"
) {
  import("./assets/ICVstyle.css").then(() => {});
} else if (
  import.meta.env.VITE_CONFIG_MODE == "ICVCNtest" ||
  import.meta.env.VITE_CONFIG_MODE == "ICVCN"
) {
  import("./assets/ICVCNstyle.css").then(() => {});
} else if (
  import.meta.env.VITE_CONFIG_MODE == "N12"
) {
  import("./assets/ICVCNstyle.css").then(() => {});
} else {
  import("./assets/style.css").then(() => {});
}

// 導入圖片預載入方法以及圖片列表
imgsPreloader(imgPreloaderList);

Object.keys(AllRules).forEach((rule) => {
  defineRule(rule, AllRules[rule]);
});

configure({
  generateMessage: localize({ zh_TW: zhTW }),
  validateOnInput: true,
});
setLocale("zh_TW");
const app = createApp(App);
app.use(i18n);
app.component("VField", Field);
app.component("VForm", Form);
app.component("ErrorMessage", ErrorMessage);
app.use(createPinia());
app.config.globalProperties.$mitt = mitt();
app.use(router);

app.mount("#app");
