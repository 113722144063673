<script setup lang="ts">
import { storeToRefs } from "pinia";
import { useRouter } from "vue-router";
import { useMemberService } from "@/stores/memberFunction/memberService";
import SidebarWeb from "@/components/menberFunction/functionNavbar/sidebarWeb.vue";
const router = useRouter();
const memberService = useMemberService();
const { CardData, openMemberBankcard } = storeToRefs(memberService);

function toAddBank() {
  router.push({ name: "MemberAddBank" });
}
function toBankVerified() {
  router.push({ name: "BankVerified" });
}

function delCard(id: string) {
  memberService.deleteMemberBankInBankPage(id);
}

const modifiedString = (str: string) => {
  if (!str) return "";
  return "*".repeat(6) + str.slice(6);
};
</script>

<template>
  <div
    class="md:grid grid-cols-12 gap-x-[3vw] block gap-y-[1dvh] px-[1vw] pb-[3rem] pt-0 md:pt-[3rem]"
  >
    <SidebarWeb class="col-span-3 hidden md:block mb-[7dvh]" />
    <div class="col-span-9 relative">
      <div
        class="px-0 py-0 md:px-[5vw] md:py-[2dvh] relative z-10 web-gold-border rounded-[24px]"
      >
        <p class="mt-[1dvh] text-[24px] text-primary font-bold hidden md:block">
          {{ $t("MemberFunction.BankPage.BankCard") }}
        </p>

        <div
          v-if="!openMemberBankcard"
          class="absolute top-1/2 left-1/2 submitting"
        ></div>

        <div class="member-bg-border p-[1em] m-[1em] rounded-[12px]" v-else>
          <div class="w-full md:w-[70%] mx-auto">
            <div
              class="relative text-primary"
              v-if="JSON.stringify(CardData) !== '{}'"
            >
              <img
                src="@image/memberFunction/11/pankcard02_bg.png"
                alt=""
                class="w-full"
              />
              <p
                class="text-[16px] md:text-[24px] absolute top-[12px] left-[12px] md:left-[24px] font-bold"
              >
                {{ CardData.sBank }}
              </p>
              <p
                class="text-[24px] md:text-[32px] absolute top-1/2 -translate-y-0 md:-translate-y-1/2 right-[12px] md:right-[24px] font-bold"
              >
                {{ modifiedString(CardData.sPayNum) }}
              </p>
              <div
                class="flex justify-between items-center px-[12px] md:px-[24px] absolute bottom-[6px] md:bottom-[12px] w-full"
              >
                <p
                  class="text-[12px] border border-yellow-300 px-1"
                  v-if="CardData.sType === '未認證'"
                >
                  {{ $t("MemberFunction.BankPage.NotYetCertified") }}
                </p>
                <p
                  class="text-[12px] md:text-[14px] border border-yellow-300 px-1"
                  v-if="CardData.sType === '認證中'"
                >
                  {{ $t("MemberFunction.BankPage.InVerification") }}
                </p>
                <p class="text-primary/50 text-[12px] md:text-[14px]">
                  {{ $t("MemberFunction.BankPage.Date") }}
                  {{ CardData.sCreateTime }}
                </p>
              </div>
            </div>
            <button class="relative" @click="toAddBank" v-else>
              <img
                src="@image/memberFunction/11/pankcard01_bg.png"
                alt=""
                class="w-full"
              />
              <span
                class="text-primary text-[40px] md:text-[100px] absolute top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2"
              >
                +
              </span>
            </button>
          </div>

          <!-- <div
            class="flex justify-center mt-[12px]"
            v-if="CardData.sType === '未認證'"
          >
            <button
              class="bg-[url('@image/memberFunction/common/btn_n.png')] bg-100 px-[1em] py-[6px]"
              @click="toBankVerified"
            >
              <p class="text-[12px] text-primary">立即認證</p>
            </button>
          </div> -->

          <p
            class="text-yellow-300 text-center text-[14px] md:text-[18px] mt-[12px]"
          >
            {{ $t("MemberFunction.BankPage.OnlyOneBankAccount") }}
          </p>
          <div class="flex justify-center items-center mt-[12px]">
            <img
              src="@image/memberFunction/11/驚嘆號icon.png"
              alt=""
              class="w-[14px]"
            />
            <p class="text-red-600 text-center text-[14px] md:text-[20px]">
              {{ $t("MemberFunction.BankPage.Notice") }}
            </p>
          </div>
          <div
            class="leading-[26px] text-primary text-[14px] md:text-[16px] my-[1vh] w-full md:w-[70%] mx-auto"
          >
            <p>
              <span class="text-red-600 mr-1">
                {{ $t("MemberFunction.BankPage.NoticeListStyle") }}</span
              >
              {{ $t("MemberFunction.BankPage.NoticeList1") }}
            </p>
          </div>
          <table
            class="leading-[20px] md:leading-[24px] text-primary text-[14px] md:text-[16px] w-full md:w-[70%] mx-auto"
          >
            <tbody>
              <tr>
                <td class="w-[30%] md:w-[25%] align-top pb-[1vh]">
                  <span class="text-red-600 mr-1">{{
                    $t("MemberFunction.BankPage.NoticeListStyle")
                  }}</span
                  >{{ $t("MemberFunction.BankPage.NoticeList2") }}
                </td>
                <td class="pb-[1vh]">
                  {{ $t("MemberFunction.BankPage.NoticeList3") }}
                </td>
              </tr>
              <tr>
                <td class="w-[25%] md:w-[25%] align-top pb-[1vh]">
                  <span class="text-red-600 mr-1">{{
                    $t("MemberFunction.BankPage.NoticeListStyle")
                  }}</span
                  >{{ $t("MemberFunction.BankPage.NoticeList4") }}
                </td>
                <td class="pb-[1vh]">
                  {{ $t("MemberFunction.BankPage.NoticeList5") }}
                </td>
              </tr>
              <tr>
                <td class="w-[25%] md:w-[25%] align-top pb-[1vh]">
                  <span class="text-red-600 mr-1">{{
                    $t("MemberFunction.BankPage.NoticeListStyle")
                  }}</span
                  >{{ $t("MemberFunction.BankPage.NoticeList6") }}
                </td>
                <td class="pb-[1vh]">
                  {{ $t("MemberFunction.BankPage.NoticeList7") }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>
