import {
  createRouter,
  createWebHistory,
  createWebHashHistory,
} from "vue-router";
import { useMemberService } from "@/stores/memberFunction/memberService";
import { useCounterStore } from "@/stores/counter";
import { useContentInfo } from "@/stores/contentInfo";
import { useLobbyList } from "@/stores/lobbyList";
import { useMaintain } from "@/stores/maintain";
import { useLogin } from "@/stores/login";
import { useLoadingPage } from "@/stores/loadingPage";

import HomeView from "../views/HomeView.vue";

import ContentView from "../components/content/contentWeb.vue";
import contentAPPDownload from "../components/content/APPDownload/contentAPPDownload.vue";
import contentService from "../components/content/Service/contentService.vue";
import contentDiscount from "../components/content/Discount/contentDiscount.vue";
import contentRankingList from "../components/content/RankingList/contentRankingList.vue";
import contentGameLobby from "../components/content/GameLobby/contentGameLobby.vue";
import GameLoadingPage from "../components/content/gameLoadingPage.vue";
import MemberView from "../components/menberFunction/memberFunctionPageWeb.vue";

import MemberAccount from "../components/menberFunction/memberAccount.vue";
import MemberManagement from "../components/menberFunction/memberManagement.vue";
import MemberList from "../components/menberFunction/memberList.vue";
import TakeOverCoin from "../components/menberFunction/takeOverCoin.vue";

import BetRecord from "../components/menberFunction/betRecordPage.vue";

import MemberMail from "../components/menberFunction/memberMail.vue";
import TradeRecord from "../components/menberFunction/tradeRecordPage.vue";
import PromoteLink from "../components/menberFunction/promoteLink.vue";
import TransferPage from "../components/menberFunction/transferPage.vue";
import WithdrawPage from "../components/menberFunction/withdrawPage.vue";
import MemberBankPage from "../components/menberFunction/memberBankPage.vue";
import MemberAddBank from "../components/menberFunction/memberAddBank.vue";
import MemberAddUSDT from "../components/menberFunction/memberAddUSDT.vue";
import BankVerified from "../components/menberFunction/bankVerified.vue";
import DepositPage from "../components/menberFunction/depositPage.vue";
import VIPPrivilege from "../components/menberFunction/VIPPrivilege.vue";
import NewMessage from "../components/menberFunction/NewMessage.vue";

import LoginView from "../views/LoginView.vue";
import MaintainView from "../views/MaintainView.vue";
import GameIFrame from "../views/LoginView.vue";

import PromotionRegView from "../views/PromotionRegView.vue";

import ShareMakeFortunePage from "../components/menberFunction/ShareMakeFortunePage.vue";
import GameIFramePage from "../views/GameIFramePage.vue";

const router = createRouter({
  // history: createWebHistory(import.meta.env.BASE_URL),
  history: createWebHashHistory(),
  routes: [
    {
      path: "/",
      name: "home",
      component: HomeView,
      children: [
        {
          name: "main",
          path: "main",
          component: ContentView,
        },
        {
          name: "APPDownload",
          path: "APPDownload",
          component: contentAPPDownload,
        },
        {
          name: "Service",
          path: "Service",
          component: contentService,
        },
        {
          name: "Discount",
          path: "Discount",
          component: contentDiscount,
        },
        {
          name: "RankingList",
          path: "RankingList",
          component: contentRankingList,
        },

        {
          name: "member",
          path: "member",
          component: MemberView,
          children: [
            {
              name: "MemberAccount",
              path: "MemberAccount",
              component: MemberAccount,
            },
            {
              name: "MemberManagement",
              path: "MemberManagement",
              component: MemberManagement,
            },
            {
              name: "MemberList",
              path: "MemberList",
              component: MemberList,
            },
            {
              name: "TakeOverCoin",
              path: "TakeOverCoin",
              component: TakeOverCoin,
            },
            {
              name: "BetRecord",
              path: "BetRecord",
              component: BetRecord,
            },
            {
              name: "MemberMail",
              path: "MemberMail",
              component: MemberMail,
            },
            {
              name: "TradeRecord",
              path: "TradeRecord",
              component: TradeRecord,
            },
            {
              name: "PromoteLink",
              path: "PromoteLink",
              component: PromoteLink,
            },
            {
              name: "TransferPage",
              path: "TransferPage",
              component: TransferPage,
            },
            {
              name: "WithdrawPage",
              path: "WithdrawPage",
              component: WithdrawPage,
            },
            {
              name: "MemberBankPage",
              path: "MemberBankPage",
              component: MemberBankPage,
            },
            {
              name: "MemberAddBank",
              path: "MemberAddBank",
              component: MemberAddBank,
            },
            {
              name: "MemberAddUSDT",
              path: "MemberAddUSDT",
              component: MemberAddUSDT,
            },
            {
              name: "BankVerified",
              path: "BankVerified",
              component: BankVerified,
            },
            {
              name: "DepositPage",
              path: "DepositPage",
              component: DepositPage,
            },
            {
              name: "VIPPrivilege",
              path: "VIPPrivilege",
              component: VIPPrivilege,
            },
            {
              name: "NewMessage",
              path: "NewMessage",
              component: NewMessage,
            },
            {
              name: "ShareMakeFortunePage",
              path: "ShareMakeFortunePage",
              component: ShareMakeFortunePage,
            },
            {
              name: "GameLobby",
              path: "GameLobby/:GameName",
              component: contentGameLobby,
            },
          ],
        },
      ],
    },
    {
      path: "/login",
      name: "login",

      component: LoginView,
    },
    {
      path: "/maintain",
      name: "maintain",
      component: MaintainView,
    },
    {
      path: "/GameIFrame",
      name: "GameIFrame",
      // route level code-splitting
      // this generates a separate chunk (About.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: GameIFrame,
    },
    {
      path: "/PromotionReg",
      name: "PromotionReg",
      component: PromotionRegView,
    },
    {
      path: "/GameLoadingPage",
      name: "GameLoadingPage",
      component: GameLoadingPage,
    },
    {
      path: "/GameIFramePage",
      name: "GameIFramePage",
      component: GameIFramePage,
    },
  ],
});

router.beforeEach(async (to, from, next) => {
  const memberService = useMemberService();
  const login = useLogin();
  const Maintain = useMaintain();
  const store = useCounterStore();
  const lobbyList = useLobbyList();
  const loadingPage = useLoadingPage();
  store.hiddenAllAlert();
  store.hiddenAlertBg();
  memberService.ClearChangepwdMsg();
  if (to.name === "member") {
    router.push({ name: "main" });
  }
  switch (to.name) {
    case "main":
      // contentWeb.vue 會有一支檢查的function
      break;
    case "login":
      loadingPage.openLoadingPage();

      // loginPage.vue 會有一支檢查的function
      // loadingPage.closeLoadingPage(); 就在檢查的function內
      break;
    case "maintain":
      Maintain.ToMaintain();
      break;
    case "GameIFrame":
      break;
    case "PromotionReg":
      login.toLoginPage();
      break;
    case "APPDownload":
      // contentService.vue 客服頁
      break;
    case "Service":
      // contentService.vue 客服頁
      break;
    case "Discount":
      memberService.toDiscountPage()
      // contentDiscount.vue 優惠頁
      break;
    case "RankingList":
      memberService.toRankingListPage();
      // contentRankingList.vue 排行榜
      break;
    case "LoadingPage":
      // loadingPage.vue 遊戲跳轉頁面
      break;
    case "MemberAccount":
      memberService.toMemberAccount();
      break;
    case "MemberManagement":
      memberService.toMemberManagement();
      break;
    case "MemberList":
      memberService.toUserList();
      break;
    case "TakeOverCoin":
      memberService.toApiBalance();
      break;
    case "BetRecord":
      memberService.toBetRecord();
      break;
    case "MemberMail":
      memberService.toMemberMail();
      break;
    case "TradeRecord":
      memberService.toTradeRecord();
      break;
    case "PromoteLink":
      memberService.toPromotion();
      break;
    case "TransferPage":
      memberService.toTransferPage();
      break;
    case "WithdrawPage":
      memberService.toWithdrawPage();
      break;
    case "MemberAddBank":
      memberService.toMemberAddBank();
      break;
    case "MemberAddUSDT":
      memberService.toMemberAddUSDT();
      break;
    case "MemberBankPage":
      memberService.toMemberBankPage();
      break;
    case "BankVerified":
      memberService.toBankVerified();
      break;
    case "DepositPage":
      memberService.toDepositPage();
      break;
    case "VIPPrivilege":
      memberService.toVipPrivilege();
      break;
    case "NewMessage":
      memberService.toNewMessage();
      break;
    case "ShareMakeFortunePage":
      // router.push({ name: "main" });
      memberService.toShareMakeFortunePage();
      break;
    case "GameLobby":
      lobbyList.ToGameLobby();
      break;
    default:
      break;
  }

  next();
});

export default router;
