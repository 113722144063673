<script setup lang="ts">
import { onMounted, ref } from "vue";
import { storeToRefs } from "pinia";
import { useLogin } from "@/stores/login";
import { useRouter } from "vue-router";
import { useLoadingPage } from "@/stores/loadingPage";
import AgreeRegisterPage from "@/components/login/agreeRegisterPage.vue";

import { useMemberService } from "@/stores/memberFunction/memberService";
import MemberServiceContent from "@/components/menberFunction/memberServiceContent.vue";
const memberService = useMemberService();
const login = useLogin();
const router = useRouter();
const loadingPage = useLoadingPage();
const { isAgreeRegisterPage } = storeToRefs(login);
function toService() {
  router.push({ name: "Service" });
}

// 0: 開, 1: 關
const Mode = ref<number>(0);
function selectMode(mode: number) {
  Mode.value = mode;
}
// 0: 開, 1: 關
const Mode2 = ref<number>(0);
function selectMode2(mode: number) {
  Mode2.value = mode;
}

const props = defineProps({
  share: String,
  promoCode: String,
  account: String,
});
const emit = defineEmits(["selectMode"]);

const promoAccount = ref<string>("");
const phoneNumber = ref<string>("");
async function Phonebtn(phoneNumber: string) {
  const sPhone = phoneNumber;
  const postData = {
    sPhone,
  };
  await login.getSendMsg(postData);
}

const agreeRegister = ref<boolean>(false);

interface FormValues {
  sAccount: string;
  sPasswd: string;
  sPasswd2: string;
  sPhone: string;
  sPhone_Vscode: string;
  sLineId: string;
  sRecommend: string;
}
const loginFrom = ref<HTMLFormElement | null>(null);
async function submit(formValues: FormValues) {
  if (login.phoneSwitch === "0") {
    const {
      sAccount,
      sPasswd,
      sPasswd2,
      sRecommend,
      sPhone,
      sPhone_Vscode,
      sLineId,
    } = formValues;
    const postData = {
      sAccount,
      sPasswd,
      sPasswd2,
      sPhone,
      sPhone_Vscode,
      sRecommend,
      sLineId,
      nShare: props.share ? props.share : "",
    };
    await login.Phoneregister(postData);
  } else {
    const { sAccount, sPasswd, sPasswd2, sRecommend, sLineId } = formValues;
    const postData = {
      sAccount,
      sPasswd,
      sPasswd2,
      sLineId,
      sRecommend,
    };
    await login.register(postData);
  }
}
function openAgreeRegisterPage() {
  login.openAgreeRegisterPage();
}
function closeAgreeRegisterPage() {
  login.closeAgreeRegisterPage();
}
function hasAccount() {
  emit("selectMode", 0);
}

onMounted(() => {
  console.log("toLoginPage");
  loadingPage.closeLoadingPage();
  loginFrom.value?.setValues({
    sRecommend: props.promoCode,
  });
  if (props.account) {
    promoAccount.value = props.account;
  }
});
</script>

<template>
  <VForm
    @submit="submit"
    v-slot="{ meta, isSubmitting, errors }"
    ref="loginFrom"
    class="text-primary flex flex-col mx-auto">
    <div class="mb-[12px]">
      <label :for="$t('LoginView.Account')" class="flex flex-col relative">
        <img src="@image/memberFunction/07/info_bar.png" alt="" />
        <div
          class="absolute top-1/2 -translate-y-1/2 left-[15px] flex justify-center items-center">
          <img
            src="@image/memberFunction/07/icon_id.png"
            alt=""
            class="w-[15px]" />
          <VField
            id="sAccount"
            class="ml-[12px] w-full h-full bg-transparent placeholder:text-primary placeholder:font-bold"
            :label="$t('LoginView.Account')"
            name="sAccount"
            type="text"
            :rules="{
              regex: /^(?=.*[a-zA-Z])(?=.*\d)[a-zA-Z0-9]{6,16}$/,
              required: true,
            }"
            :placeholder="$t('LoginView.AccountPlaceholder')" />
        </div>
        <div
          class="absolute top-1/2 -translate-y-1/2 right-[15px] flex justify-center items-center">
          <ErrorMessage name="sAccount" v-slot="{ message }">
            <p class="text-red-400 text-[10px] mr-[6px]">
              {{
                message.includes("必填")
                  ? `*${$t("LoginView.Required")}`
                  : message
              }}
            </p>
          </ErrorMessage>
        </div>
      </label>
    </div>

    <div class="mb-[12px]">
      <label :for="$t('LoginView.Password')" class="flex flex-col relative">
        <img src="@image/memberFunction/07/info_bar.png" alt="" />
        <div
          class="absolute top-1/2 -translate-y-1/2 left-[15px] flex justify-center items-center">
          <img
            src="@image/memberFunction/07/icon_pw.png"
            alt=""
            class="w-[15px]" />
          <VField
            id="sPasswd"
            class="ml-[12px] w-full h-full bg-transparent placeholder:text-primary placeholder:font-bold"
            :label="$t('LoginView.Password')"
            name="sPasswd"
            :type="Mode === 0 ? 'password' : 'text'"
            :rules="{
              regex: /^(?=.*[a-zA-Z])(?=.*\d)[a-zA-Z0-9]{6,16}$/,
              required: true,
            }"
            :placeholder="$t('LoginView.PasswordPlaceholder')" />
        </div>
        <div
          class="absolute top-1/2 -translate-y-1/2 right-[15px] flex justify-center items-center">
          <ErrorMessage name="sPasswd" v-slot="{ message }">
            <p class="text-red-400 text-[10px] mr-[6px]">
              {{
                message.includes("必填")
                  ? `*${$t("LoginView.Required")}`
                  : message
              }}
            </p>
          </ErrorMessage>
          <img
            src="@image/memberFunction/07/eye_op.png"
            alt=""
            class="w-[20px] h-[15px]"
            :class="Mode === 0 ? 'hidden' : 'block'"
            @click="selectMode(0)" />
          <img
            src="@image/memberFunction/07/eye_ed.png"
            alt=""
            class="w-[20px] h-[15px]"
            :class="Mode === 1 ? 'hidden' : 'block'"
            @click="selectMode(1)" />
        </div>
      </label>
    </div>

    <div class="mb-[12px]">
      <label
        :for="$t('LoginView.CheckPassword')"
        class="flex flex-col relative">
        <img src="@image/memberFunction/07/info_bar.png" alt="" />
        <div
          class="absolute top-1/2 -translate-y-1/2 left-[15px] flex justify-center items-center">
          <img
            src="@image/memberFunction/07/icon_pw_e.png"
            alt=""
            class="w-[15px]" />
          <VField
            id="sPasswd2"
            class="ml-[12px] w-full h-full bg-transparent placeholder:text-primary placeholder:font-bold"
            :label="$t('LoginView.CheckPassword')"
            name="sPasswd2"
            :type="Mode2 === 0 ? 'password' : 'text'"
            :rules="{
              regex: /^(?=.*[a-zA-Z])(?=.*\d)[a-zA-Z0-9]{6,16}$/,
              required: true,
            }"
            :placeholder="$t('LoginView.CheckPasswordPlaceholder')" />
        </div>
        <div
          class="absolute top-1/2 -translate-y-1/2 right-[15px] flex justify-center items-center">
          <ErrorMessage name="sPasswd2" v-slot="{ message }">
            <p class="text-red-400 text-[10px] mr-[6px]">
              {{
                message.includes("必填")
                  ? `*${$t("LoginView.Required")}`
                  : message
              }}
            </p>
          </ErrorMessage>
          <img
            src="@image/memberFunction/07/eye_op.png"
            alt=""
            class="w-[20px] h-[15px]"
            :class="Mode2 === 0 ? 'hidden' : 'block'"
            @click="selectMode2(0)" />
          <img
            src="@image/memberFunction/07/eye_ed.png"
            alt=""
            class="w-[20px] h-[15px]"
            :class="Mode2 === 1 ? 'hidden' : 'block'"
            @click="selectMode2(1)" />
        </div>
      </label>
    </div>

    <div class="mb-[12px]">
      <label :for="$t('LoginView.CheckLineId')" class="flex flex-col relative">
        <img src="@image/memberFunction/07/info_bar.png" alt="" />
        <div
          class="absolute top-1/2 -translate-y-1/2 left-[15px] flex justify-center items-center">
          <img
            src="@image/memberFunction/07/icon_line.png"
            alt=""
            class="w-[15px]" />
          <VField
            id="sLineId"
            class="ml-[12px] w-full h-full bg-transparent placeholder:text-primary placeholder:font-bold"
            :label="$t('LoginView.CheckLineId')"
            name="sLineId"
            type="text"
            :rules="{
              required: true,
            }"
            :placeholder="$t('LoginView.CheckLineIdPlaceholder')" />
        </div>
        <div
          class="absolute top-1/2 -translate-y-1/2 right-[15px] flex justify-center items-center">
          <ErrorMessage name="sLineId" v-slot="{ message }">
            <p class="text-red-400 text-[10px] mr-[6px]">
              {{
                message.includes("必填")
                  ? `*${$t("LoginView.Required")}`
                  : message
              }}
            </p>
          </ErrorMessage>
        </div>
      </label>
    </div>

    <!-- login.phoneSwitch  -->
    <div v-if="login.phoneSwitch === '0'">
      <div class="mb-[12px]">
        <label
          :for="$t('LoginView.PhoneNumber')"
          class="flex flex-col relative">
          <img src="@image/memberFunction/07/info_bar.png" alt="" />
          <div
            class="absolute top-1/2 -translate-y-1/2 left-[15px] flex justify-center items-center">
            <img
              src="@image/memberFunction/07/icon_ph_num01.png"
              alt=""
              class="w-[15px]" />
            <VField
              id="sPhone"
              class="ml-[12px] w-full h-full bg-transparent placeholder:text-primary placeholder:text-[12px] placeholder:font-bold"
              :label="$t('LoginView.PhoneNumber')"
              name="sPhone"
              type="text"
              v-model="phoneNumber"
              :rules="{
                regex: /^[0-9]{10}$/,
                required: true,
              }"
              :placeholder="$t('LoginView.PhoneNumberPlaceholder')" />
          </div>
          <div
            class="flex justify-center items-center absolute top-1/2 -translate-y-1/2 right-[6px]">
            <ErrorMessage name="sPhone" v-slot="{ message }">
              <p class="text-red-400 text-[10px] mr-[6px]">
                {{
                  message.includes("必填")
                    ? `*${$t("LoginView.Required")}`
                    : message
                }}
              </p>
            </ErrorMessage>
            <button @click="Phonebtn(phoneNumber)" type="button">
              <div class="relative">
                <img
                  class="w-[80px]"
                  src="@image/memberFunction/07/phone_code.png"
                  alt="" />
                <p
                  class="absolute top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2 text-[12px] w-[120%] text-[#000000] font-bold">
                  {{ $t("LoginView.GetVerification") }}
                </p>
              </div>
            </button>
          </div>
        </label>
      </div>

      <div class="mb-[12px]">
        <label
          :for="$t('LoginView.Verification')"
          class="flex flex-col relative">
          <img src="@image/memberFunction/07/info_bar.png" alt="" />
          <div
            class="absolute top-1/2 -translate-y-1/2 left-[15px] flex justify-center items-center">
            <img
              src="@image/memberFunction/07/key.png"
              alt=""
              class="w-[16px]" />
            <VField
              id="sPhone_Vscode"
              class="ml-[12px] w-full h-full bg-transparent placeholder:text-primary placeholder:font-bold"
              :label="$t('LoginView.Verification')"
              name="sPhone_Vscode"
              type="text"
              :rules="{
                required: true,
              }"
              :placeholder="$t('LoginView.VerificationPlaceholder')" />
          </div>
          <div
            class="absolute top-1/2 -translate-y-1/2 right-[15px] flex justify-center items-center">
            <ErrorMessage name="sPhone_Vscode" v-slot="{ message }">
              <p class="text-red-400 text-[10px] mr-[6px]">
                {{
                  message.includes("必填")
                    ? `*${$t("LoginView.Required")}`
                    : message
                }}
              </p>
            </ErrorMessage>
          </div>
        </label>
      </div>
    </div>
    <div class="mb-[12px]" v-if="promoAccount">
      <div :for="$t('LoginView.Recommend')" class="flex flex-col relative">
        <img src="@image/memberFunction/07/info_bar.png" alt="" />
        <div
          class="absolute top-1/2 -translate-y-1/2 left-[15px] flex justify-center items-center">
          <img
            src="@image/memberFunction/07/icon_store.png"
            alt=""
            class="w-[15px]" />
          <p class="text-[14px] pl-[6px] whitespace-nowrap">經銷商</p>
          <input
            id="sRecommend"
            class="ml-[12px] w-full h-full bg-transparent placeholder:text-primary placeholder:font-bold"
            :label="$t('LoginView.Recommend')"
            name="sRecommend"
            type="text"
            v-model="promoAccount"
            disabled />
        </div>
      </div>
      <label :for="$t('LoginView.RecommendAccount')" class="relative hidden">
        <img src="@image/memberFunction/07/info_bar.png" alt="" />
        <div
          class="absolute top-1/2 -translate-y-1/2 left-[15px] flex justify-center items-center">
          <img
            src="@image/memberFunction/07/icon_store.png"
            alt=""
            class="w-[15px]" />
          <VField
            id="sRecommend"
            class="ml-[12px] w-full h-full bg-transparent placeholder:text-primary placeholder:font-bold"
            :label="$t('LoginView.RecommendAccount')"
            name="sRecommend"
            type="text"
            :rules="{
              regex: /^(?=.*[a-zA-Z])(?=.*\d)[a-zA-Z0-9]{6,16}$/,
            }"
            :placeholder="$t('LoginView.RecommendCodePlaceholder')" />
        </div>
      </label>
      <ErrorMessage name="sRecommend" v-slot="{ message }">
        <p class="text-primary text-[10px] mr-[6px]">
          {{
            message.includes("必填") ? `*${$t("LoginView.Required")}` : message
          }}
        </p>
      </ErrorMessage>
    </div>

    <div
      class="flex justify-between items-center mb-[16px] mt-[8px] text-[14px]">
      <label for="agreeRegister" class="flex items-center cursor-pointer">
        <div class="relative mr-1">
          <img
            src="@image/memberFunction/07/v.png"
            alt=""
            class="absolute top-[40%] -translate-y-1/2 left-[60%] -translate-x-1/2"
            v-if="agreeRegister" />
          <img
            class="w-[25px]"
            src="@image/memberFunction/07/v_bg.png"
            alt="" />
        </div>
        <span class="text-red-400" v-if="errors.agreeRegister">*</span>
        <VField
          name="agreeRegister"
          id="agreeRegister"
          type="checkbox"
          class="hidden"
          :rules="{ required: true }"
          v-model="agreeRegister" />
        <span
          class="hover:text-primary/80"
          :class="{ 'text-red-400': errors.agreeRegister }"
          >{{ $t("LoginView.Over18") }}</span
        >
      </label>
      <button
        class="text-red-400 underline decoration-1 hover:text-red/80"
        type="button"
        @click="openAgreeRegisterPage">
        {{ $t("LoginView.Agreement") }}
      </button>
    </div>

    <p class="text-red-400 text-center">{{ login.loginMsg }}</p>
    <button
      class="relative"
      type="submit"
      :disabled="isSubmitting"
      :class="{ submitting: isSubmitting }">
      <img
        src="@image/memberFunction/07/login_big_btn.png"
        alt=""
        class="w-full h-full" />
      <p
        class="absolute top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2 text-[#342814] text-[16px] font-bold">
        {{ isSubmitting ? "" : `${$t("LoginView.Register")}` }}
      </p>
    </button>

    <div
      class="flex justify-between items-center mb-[12px] mt-[18px] px-[12px] text-primary text-[14px]">
      <button
        class="underline decoration-1 hover:text-primary/80"
        type="button"
        @click="hasAccount">
        {{ $t("LoginView.HasAccount") }}
      </button>
      <span class="h-full w-[2px] bg-white"></span>
      <button
        class="underline decoration-1 hover:text-primary/80 block md:hidden"
        @click="toService"
        type="button">
        {{ $t("LoginView.ToService") }}
      </button>
      <button
        class="underline decoration-1 hover:text-primary/80 hidden md:block"
        @click="memberService.ToggleService(false)"
        type="button">
        {{ $t("LoginView.ToService") }}
      </button>
    </div>
  </VForm>
  <div
    class="absolute top-0 left-0 bg-black/50 w-full h-full z-[60]"
    @click="closeAgreeRegisterPage"
    v-if="isAgreeRegisterPage">
    <AgreeRegisterPage />
  </div>
</template>
