<script setup lang="ts">
import { ref, onMounted, watch, onBeforeMount } from "vue";
import { storeToRefs } from "pinia";
import { useRouter, useRoute } from "vue-router";
import { useLogin } from "@/stores/login";
import { useCounterStore } from "@/stores/counter";
import { useContentInfo } from "@/stores/contentInfo";
import { useMemberService } from "@/stores/memberFunction/memberService";
import { useLoadingPage } from "@/stores/loadingPage";
import MemberServiceContent from "@/components/menberFunction/memberServiceContent.vue";
const memberService = useMemberService();
const store = useCounterStore();
const counterInfo = useContentInfo();
const loadingPage = useLoadingPage();
const login = useLogin();
const router = useRouter();

// 0: 開, 1: 關
const Mode = ref<number>(0);
function selectMode(mode: number) {
  Mode.value = mode;
}

interface FormValues {
  sAccount: string;
  sPasswd: string;
  nVscode: string;
}

// 記住密碼
const isKeepPassword = ref<boolean>(false);
watch(isKeepPassword, (nVal) => {
  if (nVal) {
    login.savePassword(
      defaultFormData.value.sPasswd,
      defaultFormData.value.sAccount,
      14
    );
  } else {
    login.keepPassword = "";
    login.keepAccount = "";
    login.savePassword(
      defaultFormData.value.sPasswd,
      defaultFormData.value.sAccount,
      -1
    );
  }
});
function checkKeepPasswordState() {
  if (login.keepPassword) {
    defaultFormData.value.sPasswd = login.keepPassword;
    defaultFormData.value.sAccount = login.keepAccount;
    isKeepPassword.value = true;
  }
}

// 表單
const loginFrom = ref<HTMLFormElement | null>(null);
const defaultFormData = ref<FormValues>({
  sAccount: "",
  sPasswd: "",
  nVscode: "",
});
async function submit(formValues: FormValues) {
  // console.log(formValues, loginFrom, "submit");
  if (isKeepPassword.value) {
    login.savePassword(formValues.sPasswd, formValues.sAccount, 14);
  }
  await login.login(formValues);
}
function resetVscode() {
  login.getVscode();
}

function toMain() {
  router.push({ name: "main" });
}

function toService() {
  router.push({ name: "Service" });
}
function toAPPDownload() {
  if (store.UserAgent === "android") {
    window.open(counterInfo.AppDownloadUrl, "_blank");
  } else {
    router.push({ name: "APPDownload" });
  }
}

onMounted(async () => {
  console.log("toLoginPage");
  login.toLoginPage();
  login.getKeepPassword();
  checkKeepPasswordState();
});
</script>

<template>
  <VForm
    @submit="submit"
    v-slot="{ meta, isSubmitting }"
    ref="loginFrom"
    class="text-primary flex flex-col mx-auto"
  >
    <div class="mb-[12px]">
      <label :for="$t('LoginView.Account')" class="flex flex-col relative">
        <img src="@image/memberFunction/07/info_bar.png" alt="" />

        <div
          class="absolute top-1/2 -translate-y-1/2 left-[15px] flex justify-center items-center"
        >
          <img
            src="@image/memberFunction/07/icon_id.png"
            alt=""
            class="w-[15px]"
          />
          <VField
            id="sAccount"
            class="ml-[12px] w-full h-full bg-transparent placeholder:text-primary placeholder:font-bold"
            :label="$t('LoginView.Account')"
            name="sAccount"
            type="text"
            :rules="{
              regex: /^(?=.*[a-zA-Z])(?=.*\d)[a-zA-Z0-9]{6,16}$/,
              required: true,
            }"
            v-model="defaultFormData.sAccount"
            :placeholder="$t('LoginView.AccountPlaceholder')"
          />
        </div>
        <div
          class="absolute top-1/2 -translate-y-1/2 right-[15px] flex justify-center items-center"
        >
          <ErrorMessage name="sAccount" v-slot="{ message }">
            <p class="text-red-400 text-[10px] mr-[6px]">
              {{ message.includes("必填") ? "*必填" : message }}
            </p>
          </ErrorMessage>
        </div>
      </label>
    </div>

    <div class="mb-[12px]">
      <label :for="$t('LoginView.Password')" class="flex flex-col relative">
        <img src="@image/memberFunction/07/info_bar.png" alt="" />
        <div
          class="absolute top-1/2 -translate-y-1/2 left-[15px] flex justify-center items-center"
        >
          <img
            src="@image/memberFunction/07/icon_pw.png"
            alt=""
            class="w-[15px]"
          />
          <VField
            id="sPasswd"
            class="ml-[12px] w-full h-full bg-transparent placeholder:text-primary placeholder:font-bold"
            :label="$t('LoginView.Password')"
            name="sPasswd"
            :type="Mode === 0 ? 'password' : 'text'"
            :rules="{
              regex: /^(?=.*[a-zA-Z])(?=.*\d)[a-zA-Z0-9]{6,16}$/,
              required: true,
            }"
            v-model="defaultFormData.sPasswd"
            :placeholder="$t('LoginView.PasswordPlaceholder')"
          />
        </div>
        <div
          class="absolute top-1/2 -translate-y-1/2 right-[15px] flex justify-center items-center"
        >
          <ErrorMessage name="sPasswd" v-slot="{ message }">
            <p class="text-red-400 text-[10px] mr-[6px]">
              {{
                message.includes("必填")
                  ? `*${$t("LoginView.Required")}`
                  : message
              }}
            </p>
          </ErrorMessage>
          <img
            src="@image/memberFunction/07/eye_op.png"
            alt=""
            :class="Mode === 0 ? 'hidden' : 'block'"
            class="w-[20px] h-[15px]"
            @click="selectMode(0)"
          />
          <img
            src="@image/memberFunction/07/eye_ed.png"
            alt=""
            :class="Mode === 1 ? 'hidden' : 'block'"
            class="w-[20px] h-[15px]"
            @click="selectMode(1)"
          />
        </div>
      </label>
    </div>

    <div class="mb-[12px]">
      <label :for="$t('LoginView.Verification')" class="flex flex-col relative">
        <div class="relative">
          <img src="@image/memberFunction/07/info_bar.png" alt="" />
          <div
            class="absolute top-1/2 -translate-y-1/2 left-[15px] flex justify-center items-center"
          >
            <img
              src="@image/memberFunction/07/key.png"
              alt=""
              class="w-[17px]"
            />
            <VField
              id="nVscode"
              class="ml-[12px] w-full h-full bg-transparent placeholder:text-primary placeholder:font-bold"
              :label="$t('LoginView.Verification')"
              name="nVscode"
              type="text"
              :rules="{ required: true }"
              :placeholder="$t('LoginView.VerificationPlaceholder')"
            />
          </div>
          <div
            class="flex justify-center items-center absolute top-1/2 -translate-y-1/2 right-[6px]"
          >
            <ErrorMessage
              name="nVscode"
              class="text-primary text-[10px]"
              v-slot="{ message }"
            >
              <p class="text-primary text-[10px] mr-[6px]">
                {{
                  message.includes("必填")
                    ? `*${$t("LoginView.Required")}`
                    : message
                }}
              </p>
            </ErrorMessage>
            <button
              @click="resetVscode()"
              class="ml-[4px] relative block"
              type="button"
            >
              <img
                class="h-[26px]"
                src="@image/memberFunction/07/phone_code.png"
                alt=""
              />
              <i
                class="absolute text-secondary text-[14px] top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2 font-bold"
                >{{ login.Vscode }}</i
              >
            </button>
          </div>
        </div>
      </label>
    </div>

    <div
      class="flex justify-between items-center px-1 mb-[16px] mt-[8px] text-[14px]"
    >
      <label for="isKeepPassword" class="flex items-center cursor-pointer">
        <div class="relative mr-1">
          <img
            src="@image/memberFunction/07/v.png"
            alt=""
            class="absolute top-[40%] -translate-y-1/2 left-[60%] -translate-x-1/2"
            v-if="isKeepPassword"
          />
          <img
            class="w-[25px]"
            src="@image/memberFunction/07/v_bg.png"
            alt=""
          />
        </div>
        <input
          name="isKeepPassword"
          id="isKeepPassword"
          type="checkbox"
          class="hidden"
          v-model="isKeepPassword"
        />
        <span class="hover:text-primary/80" type="button">{{
          $t("LoginView.RememberPassword")
        }}</span>
      </label>
      <button
        class="hover:text-primary/80 md:hidden block"
        type="button"
        @click="toService"
      >
        {{ $t("LoginView.ForgetPassword") }}
      </button>
      <button
        class="hover:text-primary/80 hidden md:block"
        type="button"
        @click="memberService.ToggleService(false)"
      >
        {{ $t("LoginView.ForgetPassword") }}
      </button>
    </div>

    <p class="text-red-400 text-center">{{ login.loginMsg }}</p>
    <button
      class="relative"
      type="submit"
      :disabled="!meta.valid || isSubmitting"
      :class="{ submitting: isSubmitting }"
    >
      <img
        src="@image/memberFunction/07/login_big_btn.png"
        alt=""
        class="w-full h-full"
      />
      <p
        class="absolute top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2 text-[#342814] text-[16px] font-bold"
      >
        {{ isSubmitting ? "" : $t("LoginView.Login") }}
      </p>
    </button>

    <div
      class="flex justify-between items-center mb-[12px] mt-[18px] px-[12px] text-primary text-[14px]"
    >
      <button
        @click="toMain"
        class="underline decoration-1 hover:text-primary/80"
        type="button"
      >
        {{ $t("LoginView.GoStore") }}
      </button>
      <span class="h-full w-[2px] bg-white"></span>
      <button
        @click="toService"
        class="underline decoration-1 hover:text-primary/80 md:hidden block"
        type="button"
      >
        {{ $t("LoginView.ToService") }}
      </button>
      <button
        @click="memberService.ToggleService(false)"
        class="underline decoration-1 hover:text-primary/80 hidden md:block"
        type="button"
      >
        {{ $t("LoginView.ToService") }}
      </button>
    </div>

    <div class="relative mt-[12px]">
      <img src="@image/memberFunction/07/logo_app_icon_bg.png" alt="" />
      <div class="absolute top-0 flex items-center h-full">
        <img
          class="w-[60px] ml-3"
          src="@image/memberFunction/07/logo_app_icon.png"
          alt=""
        />
      </div>
      <div
        class="grid grid-cols-2 absolute w-[calc(100%-52px)] right-0 top-0 h-full text-[12px]"
      >
        <p class="flex justify-center items-center">
          {{ $t("LoginView.DownloadAPP") }}
        </p>
        <button
          class="underline decoration-1 text-blue-300 hover:text-blue-400"
          type="button"
          @click="toAPPDownload"
        >
          {{ $t("LoginView.DownloadNow") }}
        </button>
        <p class="flex justify-center items-center">
          {{ $t("LoginView.PlayersTrust") }}
        </p>
        <p class="flex justify-center items-center">
          {{ $t("LoginView.EverywherePlay") }}
        </p>
      </div>
    </div>

    <div class="grid grid-cols-4 mt-[20px] text-[12px]">
      <div class="flex flex-col justify-center items-center">
        <img
          class="w-[55px]"
          src="@image/memberFunction/07/icon_01.png"
          alt=""
        />
        <p>{{ $t("LoginView.PasswordSafe") }}</p>
      </div>
      <div class="flex flex-col justify-center items-center">
        <img
          class="w-[55px]"
          src="@image/memberFunction/07/icon_02.png"
          alt=""
        />
        <p>{{ $t("LoginView.ManyEvent") }}</p>
      </div>
      <div class="flex flex-col justify-center items-center">
        <img
          class="w-[55px]"
          src="@image/memberFunction/07/icon_03.png"
          alt=""
        />
        <p>{{ $t("LoginView.FastSave") }}</p>
      </div>
      <div class="flex flex-col justify-center items-center">
        <img
          class="w-[55px]"
          src="@image/memberFunction/07/icon_04.png"
          alt=""
        />
        <p>{{ $t("LoginView.DeviceCompatible") }}</p>
      </div>
    </div>
  </VForm>
</template>
