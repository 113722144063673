<script setup lang="ts">
import { useCounterStore } from "@/stores/counter";
const store = useCounterStore();

function closeLoginAlert() {
  store.hiddenAllAlert();
  store.hiddenAlertBg();
}

const BuildName = () => {
  const mode = import.meta.env.MODE;
  let name = "";
  switch (mode) {
    case "TTBtest":
    case "TTB":
      name = "TTB娛樂";
      break;
    case "MTtest":
    case "MT":
      name = "搖錢樹娛樂";
      break;
    case "MMtest":
    case "MM":
      name = "Ming娛樂";
      break;
    case "ICVtest":
    case "ICV":
      name = "ICV國際";
      break;
    case "ICVCNtest":
    case "ICVCN":
      name = "ICV國際";
      break;
    case "white03test":
      name = "ICV國際";
      break;
    case "N12":
      name = "昊陽娛樂";
      break;
    default:
      name = "ICV國際";
      break;
  }
  return name;
};
</script>

<template>
  <div
    class="absolute top-0 xl:-top-[20%] left-1/2 -translate-x-1/2 -translate-y-1/2"
  >
    <div
      class="absolute top-[10dvh] lg:top-[5dvh] left-1/2 -translate-x-1/2 w-[95vw] lg:w-[80vw] bg-white h-[50px] rounded-t-[8px] flex justify-center items-center bg-linear-agree-register-title z-50"
    >
      <span
        class="text-center text-[20px] xl:text-[24px] text-primary font-bold"
      >
        開戶協議
      </span>
      <strong
        class="absolute right-0 pe-5 text-[30px] text-[#fff] cursor-pointer"
      >
        &times;
      </strong>
    </div>
    <div
      class="absolute top-[15dvh] lg:top-[8dvh] left-1/2 -translate-x-1/2 w-[95vw] h-[70dvh] lg:w-[80vw] lg:h-[80dvh] overflow-y-scroll"
    >
      <div
        class="p-[12px] xl:p-[3em] w-full top-0 text-black flex flex-col items-center bg-white text-[14px] xl:text-[20px]"
      >
        <h3>{{ BuildName() }}開戶協議</h3>
        <p>
          本協議由[{{ BuildName() }}網站]（以下簡稱"{{
            BuildName()
          }}"）和玩家（以下簡稱"會員"）共同簽署，旨在規範會員在{{
            BuildName()
          }}開立帳戶並參與相關娛樂活動的條件和規則。會員確認在開立帳戶之前已充分理解並同意本協議的所有條款。
        </p>
        <ul>
          <li class="p-[12px]">
            <h4 class="font-bold text-[16px] lg:text-[24px] text-yellow-400">
              1. 開戶條件
            </h4>
            <ul>
              <li>
                1-1 會員必須年滿法定年齡18歲，並有能力按照本協議進行合法的交易。
              </li>
              <li>
                1-2 會員同意根據{{
                  BuildName()
                }}的要求提交真實、準確、完整的個人資料，包括姓名、聯繫方式。
              </li>
              <li>
                1-3
                會員同意遵守當地法律法規，並保證在開戶過程中未使用任何虛假或非法信息。
              </li>
            </ul>
          </li>
          <li class="p-[12px]">
            <h4 class="font-bold text-[16px] lg:text-[24px] text-yellow-400">
              2. 帳戶設置
            </h4>
            <ul>
              <li>
                2-1 會員可根據{{ BuildName() }}提供的指引，完成帳號註冊流程。
              </li>
              <li>
                2-2
                會員必須保管好帳戶的登入帳號和密碼，並對使用該帳戶進行的所有活動負責。
              </li>
              <li>
                2-3
                會員同意妥善保管帳戶設置中的安全問題和相關信息，並不得向他人透露。
              </li>
            </ul>
          </li>
          <li class="p-[12px]">
            <h4 class="font-bold text-[16px] lg:text-[24px] text-yellow-400">
              3. 收益和風險
            </h4>
            <ul>
              <li>
                3-1 會員理解參與{{
                  BuildName()
                }}活動涉及金錢交易和投注，可能帶來損失或收益。
              </li>
              <li>
                3-2 會員同意自行承擔投注和遊戲活動的風險，{{
                  BuildName()
                }}不對會員的損失負責。
              </li>
              <li>
                3-3
                {{
                  BuildName()
                }}有權單方面決定會員是否利用異常或無風險投注對會員行為作出嚴格監控，如發現有任何違背，欺騙或利用規則與條款進行非法收益的會員，{{
                  BuildName()
                }}有權終止會員帳號及登入，並暫停使用{{
                  BuildName()
                }}，保留收益金額的權利而無須通知。
              </li>
            </ul>
          </li>
          <li class="p-[12px]">
            <h4 class="font-bold text-[16px] lg:text-[24px] text-yellow-400">
              4. 活動規則
            </h4>
            <ul>
              <li>
                4-1 會員同意遵守{{
                  BuildName()
                }}制定的活動規則，包括投注規則、返點政策和獎勵計劃等。
              </li>
              <li>
                4-2
                會員承諾不會進行任何操縱活動結果的行為，不得使用不正當手段獲得利益。
              </li>
            </ul>
          </li>
          <li class="p-[12px]">
            <h4 class="font-bold text-[16px] lg:text-[24px] text-yellow-400">
              5. 責任和義務
            </h4>
            <ul>
              <li>
                5-1 會員應遵守本協議和{{
                  BuildName()
                }}的相關規定，保護帳戶安全和個人資料。
              </li>
              <li>
                5-2 會員同意不會從事非法活動，不得冒充{{
                  BuildName()
                }}或妨礙其他會員正常參與。
              </li>
              <li>
                5-3 會員同意自行承擔因違反本協議或法律法規而產生的後果和責任。
              </li>
            </ul>
          </li>
          <li class="p-[12px]">
            <h4 class="font-bold text-[16px] lg:text-[24px] text-yellow-400">
              6. 隱私政策
            </h4>
            <ul>
              <li>
                6-1
                {{ BuildName() }}將保護會員的個人資料，並按照相關法律法規處理
              </li>
              <li>
                6-2 會員同意{{
                  BuildName()
                }}根據隱私政策處理和保護會員相關個人資料
              </li>
            </ul>
          </li>
          <li class="p-[12px]">
            <h4 class="font-bold text-[16px] lg:text-[24px] text-yellow-400">
              7. 費用和佣金
            </h4>
            <ul>
              <li>
                7-1開立帳號和使用{{
                  BuildName()
                }}服務可能涉及費用，詳細信息請參閱相關公告或規則。
              </li>
              <li>
                7-2
                {{ BuildName() }}可能根據會員的活動或盈利情況支付相應的資金。
              </li>
            </ul>
          </li>
          <li class="p-[12px]">
            <h4 class="font-bold text-[16px] lg:text-[24px] text-yellow-400">
              8. 協議期限和終止
            </h4>
            <ul>
              <li>8-1 本協議自開戶成功之日起生效，有效期為無固定期限。</li>
              <li>8-2 {{ BuildName() }}有權在合理情況下終止本協議。</li>
            </ul>
          </li>
          <li class="p-[12px]">
            <h4 class="font-bold text-[16px] lg:text-[24px] text-yellow-400">
              9. 爭議解決
            </h4>
            <ul>
              <li>
                9-1 會員和{{
                  BuildName()
                }}同意在發生爭議時進行友好協商，解決不同意見。
              </li>
            </ul>
          </li>
          <li class="p-[12px]">
            <h4 class="font-bold text-[16px] lg:text-[24px] text-yellow-400">
              10. 管轄法律
            </h4>
            <ul>
              <li>
                10-1 當您達到所居住國家法律規定的法定年齡，才能使用{{
                  BuildName()
                }}。
              </li>
              <li>
                10-2 如果您未滿18歲，在任何情況下均不得使用{{ BuildName() }}。
              </li>
              <li>
                10-3
                有些法律管轄區並未聲明在線博彩的合法性，而有些則明確規定在線博彩為非法。
                我們並未企圖讓法律禁止區域內的任何人使用娛樂場和網站。
                {{
                  BuildName()
                }}的可用性並不能解釋為我們提供或鼓勵您在任何國家(包括法律禁止的國家)內使用{{
                  BuildName()
                }}。在適用於法律所禁止的任何地方，均不得使用{{ BuildName() }}。
                會員同意單獨負責確定在所居住國家使用{{ BuildName() }}的合法性。
              </li>
              <li>
                10-4 這些《規則與條款》代表了您和{{
                  BuildName()
                }}最終的合作協議，並取代所有先前交涉您與{{
                  BuildName()
                }}的協議。請您確認已閱讀，並同意接受這些條款及細則。
              </li>
              <li>
                10-5 儘管以上規定適合一般情況，但您無法使用我們的{{
                  BuildName()
                }}，如果您身處新加坡，台灣，瓜德羅普，以色列，塞浦路斯，愛沙尼亞，保加利亞，美國領土，香港，法國和荷蘭安的列斯群島〝被禁止轄區〞
                如有任何爭議問題，{{
                  BuildName()
                }}有最終決定權。無論任何情形，就停止或更改服務或終止會員帳戶服務所可能產生之困擾，不便或損害。
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<style>
.bgLinearMailBox {
  background: linear-gradient(45deg, #2c343e, #576879);
  border-style: solid;
  border-image: linear-gradient(45deg, #563d2a, #edbe7b) 1;
  border-image-slice: 1 1 1 1;
}
.bg-linear-agree-register-title {
  background: linear-gradient(#a7bd45, #608530);
}
.bg-100 {
  background-size: 100% 100%;
}
</style>
