<script setup lang="ts">
import { storeToRefs } from "pinia";
import { useMaintain } from "@/stores/maintain";
const Maintain = useMaintain();

const { MaintainMsg } = storeToRefs(Maintain);
</script>
<template>
  <div
    class="min-h-[100dvh] bg-white flex justify-center items-center px-[6vw]"
  >
    <div class="relative">
      <p
        class="absolute left-1/2 -translate-x-1/2 top-[20%] font-bold text-[24px] h-full"
      >
        {{ MaintainMsg }}
      </p>
      <!-- <p
        class="absolute left-1/2 -translate-x-1/2 top-[20%] font-bold text-[24px] h-full"
      >
        維護中
      </p> -->
      <img
        src="@image/memberFunction/maintain/messageImage_1699243054823.jpg"
        alt=""
      />
    </div>
  </div>
</template>
